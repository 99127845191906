<template>
  <fragment>
    <div class="pflbx-heading">
        <h2>Wählen Sie Ihre gewünschten Produkte</h2>
        <p>Stellen Sie sich schnell und einfach eine auf Ihre Bedürfnisse angepasste Pflegebox zusammen.</p>
    </div>
     <div v-if="dataLoading" class="dataLoading">
          <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          Bitte warten, die Daten werden geladen.
    </div>
    <template v-if="!dataLoading">
        <div v-for="product in products" :key="product.id" class="pflbx-product" v-bind:class="{hasOptions : product.configurable}" >
            <template  v-if="!product.configurable" >
                <Product :product="product" />
            </template>
            <template v-else>
                <!--<span class="pflbx-name">{{product.name}} </span> -->
                <div v-for="option in product.configurator.options" :key="option.id" class="pflbx-option" >
                    <Product :product="option.product" :productName='product.name + " (" + product.configurator.name + " " + option.name + ")" '/>
                </div>
            </template>
        </div>
        <CreditDisplay  style="margin-top: 4em; position:relative;" />
    </template>
  </fragment>
</template>

<script>
import axios from 'axios';

import { Fragment } from 'vue-fragment';

import CreditDisplay from "@/components/configurator/CreditDisplay.vue"
import Product from "@/components/configurator/Product.vue"


export default {
    name: 'ProductSelection',
     components: {
        Fragment,
        CreditDisplay,
        Product
    },
    data() {
        return {
            dataLoading: true,
            products: null,
        }
    },
    mounted() {
        axios.get("https://pflbx-api.sonoportal.com/api/v1/product")
        //axios.get("http://localhost:8080/api/v1/product/")
        .then(response => this.loadProducts(response));
    },
    methods: {
        loadProducts(response) {
            this.dataLoading = false;
            this.products = response.data;
        }
    }
};
</script>