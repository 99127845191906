<template>
  <div>
    <div class="pflbx-usedCredit">
        <span v-bind:style="{width: credit_width}" class="percentage"></span>
        <span class="text-value">{{credit()}}% von {{total_credit}} &euro; ausgeschöpft</span>
    </div>
    <div v-show="cartNotFilled" class="cartNotFilled">
        Bitte wählen Sie Produkte im Wert von mindestens {{minimum}} € aus, um fortzufahren.
    </div>
  </div>
</template>

<script>
export default {
    name: 'CreditDisplay',
    computed: {
        total_credit: function() {
            return this.$store.state.cart.maximum;  
        },
        minimum: function() {
            return this.$store.state.cart.minimum;
        },
        credit_width() {
            return this.credit() + "%";
        },
        cartNotFilled() {
            return this.$store.state.cart.total < this.$store.state.cart.minimum;
        }
    },
    methods: {
        credit() {
            return this.$store.getters.creditUsed;
        },
    }

}
</script>

<style lang="scss">
.pflbx-usedCredit {
        display: grid;
        border-radius: 22px;
        //background-color: #f5f7f9;
        //background: lighten(#797d80, 50);
        border: 1px solid lighten(#797d80, 45);
        color: darken(#999799, 20);
        //box-shadow: 1px 1px 3px lighten(#999799, 30);
        align-items: center;
        justify-items: center; 
        height: 32px;
        z-index: 2;
        position: relative;
        //overflow: hidden;
        .percentage {
            position: absolute;
            border-bottom-left-radius: 22px;
            border-top-left-radius: 22px;
            z-index:1;
            left:0;
            background: #BDD358;
            height: 100%;
            min-width: 1%;
        }
        .text-value {
            z-index: 3;    
            font-size:2em;
            position: absolute;
            top: -2.5rem;
        }
    }
.cartNotFilled {
    position: absolute;
    width: 100%;
    padding: 1em 0;
    z-index: 10;
    background: white;
    font-size: 1.5em;
    height: 3em;
    font-weight: bold;
    text-align: center;
}
</style>