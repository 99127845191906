<template>
  <select name="insurance_name" id="insurance_name" v-model="patient.insurance_name" required>
      <option value=''>Bitte Versicherung auswählen</option>
      <option v-for="insurance in insurances" :key="insurance.name" :value="insurance.name">
          {{ insurance.name}}
      </option>
  </select>
</template>

<script>
import axios from 'axios';

export default {
    name: 'PublicInsuranceDropDown',
    computed: {
        patient: {
        get(){
            return this.$store.state.patient;  
        },
        set(value) {
            this.$store.commit('updatePatient', value);
        }
        }
  },
    data() {
        return {
            dataLoading: true,
            insurances: null
        }    
    }, 
    mounted() {
        axios.get("https://pflbx-api.sonoportal.com/api/v1/insurance/public")
        .then(response => this.loadData(response));    
    },
    methods: {
        loadData(response) {
            this.dataLoading = false;
            this.insurances = response.data;
        }
    }

}
</script>

<style>

</style>