<template>
     <button @click="nextPage" tag="button" class="pflbx-wizard-button pflbx-wizard-back">zurück</button>
</template>

<script>
export default {
    name: 'NextButton',
    props:['route'],
    methods:{
        nextPage() {
            this.$store.commit('prevPage');  
            this.$router.push(this.route);     
        }        
    }

}
</script>

<style>

</style>