<template>
<div class="pflbx-configurator">
    <ProgressIndicator />
    <!-- <div class="pflbx-heading">
        <h2>Angaben zur Kontaktperson</h2>
        <p>Bitte geben Sie hier an, durch wen die zu pflegende Person betreut wird.</p>
    </div> -->
    <form class="pflbx-form" @submit.prevent="checkForm" novalidate>
        <fieldset>
        <div class="pflbx-form-fieldset">
            <legend>Angaben zur Kontaktperson</legend>
            <div class="fullwidth form-check">
                <input type="checkbox" id="no_contact_person" v-model="carePerson.no_contact_person">
                <label>Ich möchte keine Angaben zur Kontaktperson machen</label>
            </div>
            <div class="newline form-item fullwidth">
                <span class="validation-error" v-if="errors.relation">{{ errors.relation }}</span> 
                <div class="select-wrapper" v-bind:class="{ required: !hasNoContact }">
                     <RelationDropDown  :required="!hasNoContact" :disabled="hasNoContact" v-validate  v-model="carePerson.relation"/>  
                </div>  
                <label for="relation">In welcher Beziehung steht die Kontaktperson</label>  
            </div>    
            <div class="fullwidth form-item"  v-if="carePerson.relation == 'professional_care'">
                <span class="validation-error"  v-if="errors.professional_care">{{ errors.professional_care }}</span>
                <input type="text" id="professional_care" name="professional_care" :required="carePerson.relation == 'professional_care' ? true :false" v-validate v-model="carePerson.professional_care" placeholder="ambulanter Pflegedienst">
                <label for="professional_care">Name des Pflegedienstes</label>
          </div>
            <div class="newline form-item">
                <span class="validation-error" v-if="errors.gender">{{ errors.gender }}</span>   
                <div class="select-wrapper" v-bind:class="{ required: !hasNoContact }">
                    <GenderDropDown  :required="!hasNoContact" :disabled="hasNoContact" v-validate  v-model="carePerson.gender"/>  
                </div>  
                <label for="gender">Anrede</label>  
            </div>
    
          <div class="wider form-item">
            <span class="validation-error" v-if="errors.firstname">{{ errors.firstname }}</span>
            <input type="text" name="firstname" id="firstname" :disabled="hasNoContact" :required="!hasNoContact" v-validate v-model="carePerson.firstname" placeholder="Erika">
            <label for="firstname">Vorname</label>
          </div>
          <div class="wider form-item">
            <span class="validation-error"  v-if="errors.lastname">{{ errors.lastname }}</span>
            <input type="text" id="lastname" name="lastname" :disabled="hasNoContact" :required="!hasNoContact" v-validate v-model="carePerson.lastname" placeholder="Musterfau">
            <label for="lastname">Nachname</label>
          </div>
        </div>
        </fieldset>
     <fieldset class="inline">
        <div class="pflbx-form-fieldset">
            <legend>Anschrift der Kontaktperson</legend>

          <div class="fullwidth form-item">
            <span class="validation-error"  v-if="errors.street">{{ errors.street }}</span>
            <input type="text" id="street" name="street" :disabled="hasNoContact" :required="!hasNoContact" v-validate v-model="carePerson.street" placeholder="Musterstrasse">            
            <label for="street">Straße</label>
          </div>
          <div class="newline form-item">
             <span class="validation-error"  v-if="errors.zip_code">{{ errors.zip_code }}</span>
            <input type="text" id="zip_code" name="zip_code" :disabled="hasNoContact" :required="!hasNoContact" v-validate pattern="[0-9]{5}" v-model="carePerson.zip_code" placeholder="01234">
            <label for="zip_code">PLZ</label>
          </div>
          <div class="inline-wide form-item">
            <span class="validation-error"  v-if="errors.city">{{ errors.city }}</span>
            <input type="text" id="city" name="city" :disabled="hasNoContact" :required="!hasNoContact" v-validate v-model="carePerson.city" placeholder="Musterstadt">
            <label for="city">Ort</label>
          </div>
         <div class="newline form-item fullwidth" v-if="carePerson.relation != 'professional_care'">
            <a class="form-button" @click="copyAddress">Anschrift des Patienten übernehmen</a>
            <label></label>
        </div>
        </div>
        </fieldset>
     <fieldset class="inline">
        <div class="pflbx-form-fieldset">
            <legend>Kontaktdaten</legend>

            <div class="newline form-item half">
                <span class="validation-error"  v-if="errors.email">{{ errors.email }}</span>
                <input type="email" id="email" name="email" required v-validate v-model="contactEmail" placeholder="email@emailprovider.de">
                <label for="email">Email</label>
            </div>
            <div class="form-item half">
                <span class="validation-error"  v-if="errors.phone">{{ errors.phone }}</span>
                <input type="phone" id="phone" name="phone" required v-validate v-model="contactPhone" placeholder="01234/123456" >
                <label for="phone">Telefon</label>
            </div>
        </div>
        </fieldset>
        <fieldset>
        <div class="pflbx-form-fieldset auto-rows">
            <div class="legend">Lieferung</div>
            <!--<div class="radio-group fullwidth" style="--radios:1;margin-top: 2em;"> -->
                <div class="form-radio fullwidth">
                    <span class="radio-input">
                    <input
                        type="radio"
                        id="patient"
                        name="delivery_to"
                        value="patient"
                        required
                        v-validate
                        v-model="cart.delivery_to"
                    />
                    <span class="radio-control"></span>
                    </span>
                    <label for="patient">Die Lieferung soll an die Adresse der zu <b>pflegenden Person</b> erfolgen</label>
                </div>    
                <div class="form-radio fullwidth">
                    <span class="radio-input">
                    <input
                        type="radio"
                        id="carePerson"
                        name="delivery_to"
                        value="carePerson"
                        required
                        v-validate
                        v-model="cart.delivery_to"
                        :disabled="hasNoContact" 
                    />
                    <span class="radio-control"></span>
                    </span>
                    <label for="carePerson">Die Lieferung soll an die Adresse der <b>Kontaktperson</b> erfolgen</label>
                </div>   
                 <div class="form-radio fullwidth">
                    <span class="radio-input">
                    <input
                        type="radio"
                        id="pickup"
                        name="delivery_to"
                        value="pickup"
                        required
                        v-validate
                        v-model="cart.delivery_to"
                    />
                    <span class="radio-control"></span>
                    </span>
                    <label for="pickup">unserePflegebox wird im <b>Sanitätshaus sonoportal</b> abgeholt</label>
                </div>     
            </div>
        <!--</div> -->
        </fieldset>
        <div class="pflbx-wizard-navigation">
            <BackButton route="/pflegehilfsmittel/patient" />
            <button type="submit" tag="button" class="pflbx-wizard-button pflbx-wizard-next">weiter</button>
        </div>
    </form>
</div>
</template>

<script>
import ProgressIndicator from "@/components/configurator/ProgressIndicator.vue";
import BackButton from "@/components/configurator/BackButton.vue";
import GenderDropDown from "@/components/form/GenderDropDown.vue";
import RelationDropDown from "@/components/form/RelationDropDown.vue";
import { ValidateMixin } from '@/validate';

export default {
    name: 'CarePerson',
    mixins: [ValidateMixin],
    components: {
        ProgressIndicator,
        BackButton,
        GenderDropDown,
        RelationDropDown,
    },
    computed: {
        patient: {
            get() {
                return this.$store.state.patient;
            },
            set(value) {
                this.$store.commit('updatePatient', value);   
            }
        },
        carePerson: {
            get() {
                return this.$store.state.care_person;
            },
            set(value) {
                this.$store.commit('updateCarePerson', value);
            }
        },
         cart: {
            get() {
                return this.$store.state.cart;
            },
            set(value) {
                this.$store.commit('updateCarePerson', value);
            }    
        },
        hasNoContact: {
            get() {
                console.log("contact");
                //return true;
                return this.$store.state.care_person.no_contact_person;  
            }    
        },
        contactEmail: {
            get() {
                if(this.hasNoContact) {
                    return this.patient.email;      
                } else {
                     return this.carePerson.email;        
                }
            },
            set(value) {
                if(this.hasNoContact) {
                    this.$store.commit('updatePatientEmail', value);     
                } else {
                    this.$store.commit('updateCareEmail', value);
                }
            } 
        },
        contactPhone: {
            get() {
                if(this.hasNoContact) {
                    return this.patient.phone;      
                } else {
                     return this.carePerson.phone;        
                }
            },
            set(value) {
                if(this.hasNoContact) {
                    this.$store.commit('updatePatientPhone', value);     
                } else {
                    this.$store.commit('updateCarePhone', value);
                }
            } 
        }
    },
    methods: {
      checkForm() {
        this.validate();
        if(this.hasErrors) {
          console.log('errors');
          return;
        } 
        this.$store.commit('nextPage'); 
        this.$router.push('/pflegehilfsmittel/abschluss');  
      },
      copyAddress() {
          var patient = this.$store.state.patient;
          this.carePerson.street = patient.street;
          this.carePerson.zip_code = patient.zip_code;
          this.carePerson.city = patient.city;
      },
    }

}
</script>

<style>

</style>