import Vue from "vue";
import Vuex from "vuex";
//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  //plugins: [createPersistedState()],
  state: {
    cart: {
      //change maximum value here
      maximum: 40,
      minimum: 30,
      total: 0,
      content: {}, //Object.create(null), // new Map(),
      delivery_to: 'patient'
    },
    patient: {
      dob:null,
      gender:'',
      firstname:'',
      lastname:'',
      street:'',
      zip_code:'',
      city:'',
      email:'',
      phone:'',
      insurance_type:'GENERAL',
      insurance_name:'',
      insurance_no:'',
      handicap:'',
      public_health:false,
      signature: '',
      test:null,
    },
    care_person: {
      type: 'personal',
      relation: '',
      gender: '',      
      firstname: '',
      lastname: '',
      professional_care:'',
      street:'',
      zip_code:'',
      city:'',
      email:'',
      phone:'',
      care_power:'',
      no_contact_person: false,
    },
    currentStep: 0,
  },
 getters: {
    creditLeft(state) {
      return state.cart.maximum - state.cart.total;
    },
    creditUsed(state) {
      let creditUsed = ((state.cart.total / state.cart.maximum) * 100).toFixed(0);
      return creditUsed;
    },
    content(state) {
      return state.cart.content;
    },
    currentStep(state) {
      return state.currentStep;
    },
  }, 
  mutations: {
    addProduct(state, payload) {
      var prodName = payload.name;
      if(prodName == undefined) 
        prodName = payload.product.name;

      console.log(prodName);
      var product = payload.product;
      var id = product.id;
      var price = parseFloat(product.calculatedPrice.totalPrice);
      var cartContent = state.cart.content[id];
      if(cartContent == null || cartContent === undefined){
        var cartEntry= {
          amount: 1,
          name: prodName,
          number: product.productNumber
        };
        Vue.set(state.cart.content, id, cartEntry);   
      } else {
        cartContent.amount += 1
        Vue.set(state.cart.content, id, cartContent);     
      }
      state.cart.total += price;
      console.log(price);
      console.log(state.cart.total);
    }, 
    removeProduct(state, product) {
      var id = product.id;
      var price = parseFloat(product.calculatedPrice.totalPrice);
      
      var cartContent = state.cart.content[id];
      cartContent.amount -= 1;
      if(cartContent.amount > 0) {
        Vue.set(state.cart.content, id, cartContent); 
      } else {
        Vue.delete(state.cart.content, id);
      }
      state.cart.total -= price;

      console.log(price);
      console.log(state.cart.total);
    },
    nextPage(state) {
      state.currentStep++;
    },
    prevPage(state) {
      state.currentStep--;  
    },
    updatePatient(state, value) {
      state.patient = value;
    },
    updateCarePerson(state, value){
      state.care_person = value;
    },
    updateCart(state, value) {
      state.cart = value;
    },
    setSignature(state, signature) {
      state.patient.signature = signature;
    },
    updatePatientPhone(state, value){
      state.patient.phone = value;
    },
    updatePatientEmail(state, value) {
      state.patient.email = value;
    },
    updateCarePhone(state, value) {
      state.care_person.phone = value;
    },
    updateCareEmail(state, value) {
      state.care_person.email = value;
    },
  },
  actions: {},
  modules: {}
});
