import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PatientData from '../views/PatientData.vue';
import CarePerson from '../views/CarePerson.vue';
import Summary from '../views/Summary.vue';
import FinalPage from '../views/FinalPage.vue';

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/pflegehilfsmittel",
    name: "Home",
    component: Home,
    meta: {title: 'Produktauswahl - unserePflegebox.de'},
    step: 0
  }, {
    path: "/pflegehilfsmittel/patient",
    name: PatientData,
    component: PatientData,
    meta: {title: 'Patientendaten - unserePflegebox.de'},
    step: 1
  }, {
    path: "/pflegehilfsmittel/kontakt",
    name: CarePerson,
    component: CarePerson,
    meta: {title: 'Kontaktperson - unserePflegebox.de'},
    step: 2
  }, {
    path: "/pflegehilfsmittel/abschluss",
    name: Summary,
    component: Summary,
    meta: {title: 'Abschluss - unserePflegebox.de'},
    step:3
  }, {
    path: "/pflegehilfsmittel/final",  
    name: FinalPage,
    component: FinalPage,
    meta: {title: 'Vielen Dank - unserePflegebox.de'},

    //step:4
  }
];
const DEFAULT_TITLE = 'unserePflegebox.de - Pflegehilfsmittel einfach und bequem bestellen';

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  let parts = to.path.split('/');
  let step = parts[parts.length - 1];

  if(step !== 'pflegehilfsmittel' && (store.state.currentStep == 0)) {
    store.state.currentStep = 0;
    next("/pflegehilfsmittel"); 
  }  else {
    next();
  } 
});

router.afterEach((to) =>{
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
