<template>
<select name="gender" id="gender" :value="value" @input="$emit('input', $event.target.value)" >
    <option :value='null'>Bitte wählen</option>
    <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.displayName }}
    </option>
        
</select>
  
</template>

<script>
export default {
    name: "GenderDropDown",
    props: {    
        value: String,
    },
    data() {
        return {
            options:  [
            {   value: 'FEMALE', 
                displayName: 'Frau'
            },
            {
                value: 'MALE', 
                displayName: 'Herr'    
            },
            {
                value: 'DIVERSE',
                displayName: 'Divers'
            },
            {
                value: 'NA',
                displayName: 'Keine Angabe'   
            }
        ]
        }
    }, 
}
</script>

<style>

</style>