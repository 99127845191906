<template>
    <!--<fragment> -->
    <vueSignature ref="signature" @blur="save()"></vueSignature> 
    <!--<button @click="save">Save</button>
    </fragment>-->
		
</template>

<script>
import vueSignature from "vue-signature"

export default {
    name: 'SignaturePad', 
    components: {
        vueSignature,
    },
    methods:{
		save(){
			var _this = this;
			var png = _this.$refs.signature.save()
			var jpeg = _this.$refs.signature.save('image/jpeg')
			var svg = _this.$refs.signature.save('image/svg+xml');
			this.$store.commit('setSignature', png);
            
            console.log(png);
			console.log(jpeg)
			console.log(svg)
		},
    }

}
</script>

<style>

</style>