<template>
  <ol class="pflbx-progress">
      <li :class="className(0)" ><span>Produkte</span></li>
      <li :class="className(1)"><span>Patientendaten</span></li>
      <li :class="className(2)"><span>Kontaktperson &amp; Lieferung</span></li>
      <li :class="className(3)"><span>Abschluss</span></li>
      <!-- <li :class="className(4)"><span>Lieferadresse</span></li>
      <li :class="className(5)"><span>Abschluss</span></li>-->
  </ol>
</template>

<script>
export default {
    name: "ProgressIndicator",
    methods: {
        className(id) {
            if(id < this.$store.getters.currentStep) {
                return "visited"; 
            } else if (id == this.$store.getters.currentStep) {
                return "active";
            }     
            return "";
        }    
    }

}
</script>

<style lang="scss">
$numSteps: 4;
.pflbx-progress {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat($numSteps, 1fr);
    padding: 0;
    counter-reset: progress-counter;
    font-size: 10px;
    background-color: #e3e4e5;
    border-radius:1.25em;
    @media(min-width: 1024px) {
        font-size:12px;    
    }
    @media(min-width: 1280px) {
        font-size: 14px;    
    }
    li {
        counter-increment: progress-counter;
        color: black;
        display: grid;
        grid-template-columns: 1fr 2.3em;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 1em;
        &:first-child {
            span {
                border-top-left-radius: 1.25em;
                border-bottom-left-radius: 1.25em;
            }    
        }
        &:last-child {
            &:before {
                right:0;    
            }
        }
        &.active, &.visited {
            span {
             background: #f59c28;
             color: white;
             z-index:2;

            }
             &:before {
                background: linear-gradient(90deg, #6a7c85 50%, #465a61 50%);
             }
        }
        &.visited {
            &:after {
                content: '';
                width:100%;
                @media(min-width: 740px) {
                    background: #f59c28;
                }
                display:block;
                grid-column:2;
                height:100%;    
            }     
        }
        span{
            display: none;
            @media(min-width: 667px) {
                display: inline-grid;
                
            }
            grid-column: 1;    
            grid-row: 1;
            width: 100%;
           
            padding: .5em 1em;
        }
        &:before{
            content: counter(progress-counter);
            font-size: 1.2em;
            grid-column: 2;
            grid-row: 1;
            display: grid;
            align-items: center;
            justify-content: center;
            //border: 1px solid black;
            background: #f59c28;
            color: white;
            border-radius: 50%;
            height: 2.3em;
            width: 2.3em;
            //margin-left: -.5em;
            z-index: 3;
            font-weight: bold;
            position: absolute;
            right:.25em;
        }
    }    
}

</style>