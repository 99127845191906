<template>
<select name="relation" id="relation" :value="value" @input="$emit('input', $event.target.value)" >
    <option :value='null'>Bitte wählen</option>
    <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.displayName }}
    </option>
        
</select>
</template>

<script>
export default {
    name: "RelationDropDown",
    props: {    
        value: String,
    },
    data() {
        return {
            options:  [
            {   value: 'partner', 
                displayName: 'Ehe- / Lebenspartner'
            },
            {
                value: 'child', 
                displayName: 'Tochter / Sohn'    
            },
            {
                value: 'inlaw',
                displayName: 'Schwiegertochter / -sohn'
            },
            {
                value: 'parent',
                displayName: 'Mutter / Vater'   
            },
            {
                value: 'friend',
                displayName: 'Freund/in - Bekannte/r'   
            },
            {
                value: 'guardian',
                displayName: 'Betreuer/in'   
            },
            {
                value: 'professional_care',
                displayName: 'Ambulanter Pflegedienst'   
            }
        ]
        }
    }, 
}
</script>

<style>

</style>