<template>
<select name="handicap" id="handicap" :value="value" @input="$emit('input', $event.target.value)" >
    <option :value='null'>Bitte wählen</option>
    <option v-for="option in options" :key="option.value" :value="option.value">
        {{ option.displayName }}
    </option>
        
</select>
</template>

<script>
export default {
    name: "HandicapDropDown",
    props: {    
        value: String,
    },
    data() {
        return {
            options:  [
            {   value: 'HANDICAP_0', 
                displayName: 'kein Pflegegrad'
            },
            {
                value: 'HANDICAP_1', 
                displayName: 'Pflegegrad 1'    
            },
            {
                value: 'HANDICAP_2',
                displayName: 'Pflegegrad 2'
            },
            {
                value: 'HANDICAP_3',
                displayName: 'Pflegegrad 3'   
            },
            {
                value: 'HANDICAP_4',
                displayName: 'Pflegegrad 4'   
            },
            {
                value: 'HANDICAP_5',
                displayName: 'Pflegegrad 5'   
            }
        ]
        }
    }, 
}
</script>

<style>

</style>