import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMatomo from 'vue-matomo';

import './styles/main.scss';

Vue.config.productionTip = false;

Vue.use(VueMatomo, {
  host: "https://statistics.sonoportal.com",
  siteId: 4,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
