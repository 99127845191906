<template>
<div class="pflbx-configurator">
    <ProgressIndicator />
    <div class="pflbx-heading">
        <!--<h2>Zusammenfassung</h2>
         <p>Hier sehen Sie alle Angaben zu Ihrer persönlichen <img src="../assets/unserepflegebox.svg" alt="unserePflegebox" style="height: 1.7em; margin-bottom: -.3em;" /> auf einen Blick.</p>
 -->    </div>
     <form class="pflbx-form grid-form" @submit.prevent="submit" novalidate>
        <fieldset>
            <div class="pflbx-form-fieldset summary">
                <legend><span>Inhalt Ihrer <img src="../assets/unserepflegebox.svg" alt="unserePflegebox" style="height: 1.6em; margin-bottom: -.3em;" /></span></legend>    
                <div v-for="product in products" :key="product.id" class="fullwidth">
                    {{product.amount}}x
                    {{product.name}}
                </div>
            </div>
        </fieldset>
        <fieldset class="inline">
            <div class="pflbx-form-fieldset summary">
                <legend>Angaben zum Patienten</legend>
                <div class="fillrow">
                    {{patientGender}} {{patient.firstname}} {{patient.lastname}}
                </div>
                <div class="fillrow">
                    {{patient.street}} {{patient.zip_code}} {{patient.city}}
                </div>
                <label v-if="patient.email">
                    Email:
                </label>
                <a :href="`mailto:${patient.email}`" class="fillrow" v-if="patient.email">{{patient.email}}</a>
                <label v-if="patient.phone">
                    Telefon:
                </label>
                <div class="fillrow" v-if="patient.phone">
                    {{patient.phone}}
                </div>
                <div class="fillrow">{{insuranceType}} versichert</div>
                <label>Versicherungsname:</label>
                <div class="fillrow">{{patient.insurance_name}}</div>
                <label>Versicherungsnummer:</label>
                <div class="fillrow">{{patient.insurance_no}}</div>
                <label>Pflegegrad</label>
                <div class="fillrow">{{handicap}}</div>
            </div>
        </fieldset>
         <fieldset class="inline">
            <div class="pflbx-form-fieldset summary">
                <legend>Angaben zur Kontaktperson</legend>
                <div class="fillrow" v-if="carePerson.professional_care">
                    {{carePerson.professional_care}}
                </div>
                <div class="fillrow">
                    {{carePersonGender}} {{carePerson.firstname}} {{carePerson.lastname}}
                </div>
                <div class="fillrow">
                    {{carePerson.street}} {{carePerson.zip_code}} {{carePerson.city}}
                </div>
                <label v-if="carePerson.email">
                    Email:
                </label>
                <a :href="`mailto:${carePerson.email}`" class="fillrow" v-if="carePerson.email">{{carePerson.email}}</a>
                <label>
                    Telefon:
                </label>
                <div class="fillrow">
                    {{carePerson.phone}}
                </div>
            </div>
         </fieldset>
         <fieldset>
             <div class="pflbx-form-fieldset summary">
                <legend>Angaben zur Lieferung</legend>    
                <div class="fillrow" v-if="deliveryTo == 'patient'">Die Lieferung erfolgt an die Adresse der zu pflegenden Person </div>
                <div  class="fillrow" v-if="deliveryTo == 'carePerson'">Die Lieferung erfolgt an die Adresse der Kontaktperson</div>
                <div  class="fillrow" v-if="deliveryTo == 'pickup'"><img src="../assets/unserepflegebox.svg" alt="unserePflegebox" style="height: 1.6em; margin-bottom: -.3em;" /> 
                wird im Sanitätshaus sonoportal abgeholt</div>
                <template v-if="deliveryTo != 'pickup'">
                    <div class="fillrow" v-if="deliveryTo == 'carePerson' && carePerson.professional_care">
                        {{deliveryAddress.professional_care}}
                    </div>
                    <div class="fillrow">
                    {{deliveryAddress.firstname}} {{deliveryAddress.lastname}}
                    </div>
                    <div class="fillrow">
                        {{deliveryAddress.street}} 
                    </div>
                    <div class="fillrow">
                        {{deliveryAddress.zip_code}} {{deliveryAddress.city}}
                    </div>
                </template>
             </div>
         </fieldset>
         <fieldset>
             <div class="pflbx-form-fieldset summary">
                 <legend>Unterschrift der zu pflegenden Person</legend>
                 <div class="fullwidth signature">
                    <div style="height: 150px;"><SignaturePad ref="signature"/></div>
                    <label>Sie können hier per Maus, Finger oder elektronischem Stift unterschreiben</label>
                 </div>
             </div>    
         </fieldset>

            
      <div class="pflbx-wizard-navigation submit-order">
            <BackButton route="/pflegehilfsmittel/kontakt" />
            <button type="submit" tag="button" class="pflbx-wizard-button pflbx-wizard-next"><img src="../assets/unserepflegebox.svg" alt="unserePflegebox" style="height: 1.6em; margin-bottom: -.2em;" /> bestellen</button>
        </div>
     </form>
   
</div>
</template>

<script>
import axios from 'axios';

import ProgressIndicator from "@/components/configurator/ProgressIndicator.vue";
import BackButton from "@/components/configurator/BackButton.vue";
import SignaturePad from '@/components/SignaturePad.vue';
//import { ValidateMixin } from '@/validate';

export default {
    name: 'Summary',
     components: {
        ProgressIndicator,
        BackButton,
        SignaturePad
    },
    computed: {
        products: {
            get() {
                return this.$store.state.cart.content;
            }
        },
        patient: {
            get() {
                return this.$store.state.patient;
            }
        },
        carePerson: {
            get() {
                return this.$store.state.care_person;
            }
        },
        patientGender: {
            get() {
                var gender = this.patient.gender;
                switch (gender) {
                    case "FEMALE":
                        return "Frau";
                    case "MALE":
                        return "Herr";
                    default:
                        return "";
                }  
            }       
        },
        insuranceType: {
            get() {
                switch(this.patient.insurance_type) {
                    case "GENERAL":
                        return "gesetzlich";
                    case "PRIVATE":
                        return "privat";
                    default:
                        return "über Orts- / Sozialamt"
                }
            }
        },
        handicap: {
            get() {
                switch(this.patient.handicap) {
                    default:
                        return "kein Pflegegrad";
                    case "HANDICAP_1":
                        return "Pflegegrad 1";
                    case "HANDICAP_2":
                        return "Pflegegrad 2";
                    case "HANDICAP_3":
                        return "Pflegegrad 3";
                    case "HANDICAP_4":
                        return "Pflegegrad 4";
                    case "HANDICAP_5":
                        return "Pflegegrad 5";
                }
            }
        },
        carePersonGender: {
            get() {
                var gender = this.carePerson.gender;
                switch (gender) {
                    case "FEMALE":
                        return "Frau";
                    case "MALE":
                        return "Herr";
                    default:
                        return "";
                }      
            }    
        },
        deliveryTo: {
            get() {
                return this.$store.state.cart.delivery_to;
            }
        },
        deliveryAddress: {
            get() {
                switch(this.deliveryTo) {
                    case "patient":
                        return this.patient;
                    case "carePerson":
                        return this.carePerson;
                    default:
                        return null;
                }
            }
        }

    },
    methods: {
        submit() {
            var signature = this.$refs.signature;
            signature.save();    
            var cart = this.$store.state;
            axios.post("https://pflbx-api.sonoportal.com/api/v1/order", cart)
            //axios.post("http://localhost:8080/api/v1/order", cart)
            .then(this.$router.push('/pflegehilfsmittel/final'))
            .catch(error => {
                console.error(error);    
            });
        } 

    }
}
</script>

<style>

</style>