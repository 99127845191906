<template>
<div class="pflbx-configurator">
    <div class="pflbx-heading">
        <h2 style="text-align:center;">Vielen Dank für Ihre Bestellung auf  <img src="../assets/unserepflegebox.svg" alt="unserePflegebox" style="height: 1.7em; margin-bottom: -.3em;" /> </h2>
    </div>
    <p style="font-size: 16px; line-height: 2em; max-width: 40em; text-align:justify; margin:0 auto;">
        Unsere Mitarbeiter werden nun alles nötige für den Versand Ihrer persönlichen <img src="../assets/unserepflegebox.svg" alt="unserePflegebox" style="height: 1.7em; margin-bottom: -.3em;" />
        vorbereiten. Bitte beachten Sie, dass bei einer erstmaligen Bestellung Ihre Krankenkasse den Bezug von Pflegehilfsmitteln erst genehmigen muss. Erst nach der Genehmigung 
        dürfen wir Sie kostenfrei beliefern. Die Genehmigung nimmt dabei ein bis zwei Werktage in Anspruch. Vielen Dank für Ihr Verständnis.
    </p>
    <p style="font-size: 16px; line-height: 2em; max-width: 40em; text-align:justify; margin:0 auto;">
        Sie haben Fragen zu Pflegehilfsmitteln oder andere Fragen zur Pflege? Kontaktieren Sie uns einfach per <a href="mailto://pflege@sonoportal.com">Email</a>, 
        <a href="https://wa.me/4985049555670">Whatsapp</a> oder telefonisch unter <a href="tel:+4985049555670">08504 9555670</a>. Wir sind von Montag bis Freitag 
        von 8 - 18 Uhr und Samstags von 9 - 12 Uhr für Sie erreichbar. 
    </p>
   
</div>
</template>

<script>


export default {
    name: 'Summary',
     components: {
    
    },
    methods: { 
    }
}
</script>

<style>

</style>