<template>
  <div class="pflbx-configurator">
      <ProgressIndicator />
      <form class="pflbx-form" @submit.prevent="checkForm" novalidate>
        <fieldset>
        <div class="pflbx-form-fieldset">
          <legend>Persönliche Angaben der Patient*in</legend>
          <div class="form-item">
            <span class="validation-error" v-if="errors.dob">{{ errors.dob }}</span>
            <input type="date" onfocus="(this.type='date')" name="dob" id="dob" required v-model="patient.dob" v-validate placeholder="dd.mm.yyyy" min="1905-01-01">
            <label for="dob" >Geburtsdatum</label>
          </div>
            <div class="newline form-item">
            <span class="validation-error" v-if="errors.gender">{{ errors.gender }}</span>   
            <div class="select-wrapper required">
              <GenderDropDown  required v-validate  v-model="patient.gender"/>  
            </div>  
            <label for="gender">Anrede</label>  
          </div>

          <div class="wider form-item">
            <span class="validation-error" v-if="errors.firstname">{{ errors.firstname }}</span>
            <input type="text" name="firstname" id="firstname" required v-validate v-model="patient.firstname" placeholder="Erika">
            <label for="firstname">Vorname</label>
          </div>
          <div class="wider form-item">
            <span class="validation-error"  v-if="errors.lastname">{{ errors.lastname }}</span>
            <input type="text" id="lastname" name="lastname" required v-validate v-model="patient.lastname" placeholder="Musterfau">
            <label for="lastname">Nachname</label>
          </div>
          </div>
        </fieldset>
        <fieldset class="inline">
        <div class="pflbx-form-fieldset">
          <legend>Anschrift &amp; Kontakt</legend>
          <div class="fullwidth form-item">
            <span class="validation-error"  v-if="errors.street">{{ errors.street }}</span>
            <input type="text" id="street" name="street" required v-validate v-model="patient.street" placeholder="Musterstrasse">            
            <label for="street">Straße</label>
          </div>
          <div class="newline form-item">
            <span class="validation-error"  v-if="errors.zip_code">{{ errors.zip_code }}</span>
            <input type="text" id="zip_code" name="zip_code" required v-validate pattern="[0-9]{5}" v-model="patient.zip_code" placeholder="01234">
            <label for="zip_code">PLZ</label>
          </div>
          <div class="inline-wide form-item">
            <span class="validation-error"  v-if="errors.city">{{ errors.city }}</span>
            <input type="text" id="city" name="city" required v-validate v-model="patient.city" placeholder="Musterstadt">
            <label for="city">Ort</label>
          </div>
          <div class="form-item half">
            <span class="validation-error"  v-if="errors.email">{{ errors.email }}</span>
            <input type="email" id="email" name="email" v-validate v-model="patient.email" placeholder="email@emailprovider.de" >
            <label for="email">Email</label>
          </div>
          <div class="form-item half">
            <input type="phone" id="phone" name="phone" v-model="patient.phone" placeholder="01234/123456">
            <label for="phone">Telefon</label>
          </div>
        </div>
        </fieldset>
              <fieldset class="inline">
      <div class="pflbx-form-fieldset">
        <legend>Versicherungsdaten</legend>
        <div class="radio-group fullwidth" style="--radios:3;">
          <div class="form-radio">
            <span class="radio-input">
              <input
                type="radio"
                id="standard"
                name="insurance"
                value="GENERAL"
                required
                v-validate
                v-model="patient.insurance_type"
              />
              <span class="radio-control"></span>
            </span>
            <label for="standard">gesetzl. versichert</label>
          </div>
          <div class="form-radio">
            <span class="radio-input">
              <input
                type="radio"
                id="private"
                name="insurance"
                value="PRIVATE"
                v-model="patient.insurance_type"
              />
              <span class="radio-control"></span>
            </span>
            <label for="private">privat versichert</label>
          </div>
          <div class="form-radio">
            <span class="radio-input">
              <input
                type="radio"
                id="other"
                name="insurance"
                value="OTHER"
                v-model="patient.insurance_type"
              />
              <span class="radio-control"></span>
            </span>
            <label for="other">Orts- / Sozialamt</label>
          </div>
        </div>
        <div class="newline form-item fullwidth">
          <span class="validation-error" v-if="errors.insurance_name">{{ errors.insurance_name }}</span>
          <div class="select-wrapper required" v-if="patient.insurance_type == 'GENERAL'">
            <PublicInsuranceDropDown  required v-validate/>
          </div>
          <input
            type="text"
            name="insurance_name"
            id="insurance_name"
            v-model="patient.insurance_name"
            v-if="patient.insurance_type !== 'GENERAL'"
          />
          <label for="insurance">Versicherungsname</label>
        </div>  
        <div class="form-item half">
          <span class="validation-error" v-if="errors.insurance_no">{{ errors.insurance_no}}</span>
          <input
            type="text"
            name="insurance_no"
            id="insurance_no"
            v-model="patient.insurance_no"
            :required="patient.insurance_type == 'GENERAL'"
            v-validate
          />
          <label for="insurance_no">Versichertennummer</label>
        </div>
        <div class="form-item half">
          <span class="validation-error" v-if="errors.handicap">{{ errors.handicap}}</span>
          <div class="select-wrapper required">
            <HandicapDropDown required v-validate v-model="patient.handicap" />
            </div>
          <label for="handicap">Pflegegrad</label>
        </div>
        </div>
      </fieldset>
         <div class="pflbx-wizard-navigation">
        <BackButton route="/pflegehilfsmittel" />
        <button type="submit" tag="button" class="pflbx-wizard-button pflbx-wizard-next">weiter</button>
      </div>
      </form>
     
    </div>
</template>

<script>
import ProgressIndicator from "@/components/configurator/ProgressIndicator.vue"
import BackButton from "@/components/configurator/BackButton.vue";
import { ValidateMixin } from '@/validate';
import PublicInsuranceDropDown  from "@/components/PublicInsuranceDropDown.vue";
import GenderDropDown from "@/components/form/GenderDropDown.vue";
import HandicapDropDown from "@/components/form/HandicapDropDown.vue";

export default {
  name: "PatientData",
  mixins: [ValidateMixin],
  components: {
    ProgressIndicator,
    BackButton,
    PublicInsuranceDropDown,
    //NextButton
    GenderDropDown,
    HandicapDropDown

  }, 
  computed: {
    patient: {
      get(){
        return this.$store.state.patient;  
      },
      set(value) {
        this.$store.commit('updatePatient', value);
      }
    }
  },
    methods: {
      checkForm() {
        this.validate();
        if(this.hasErrors) {
          console.log('errors');
          return;
        } 
        this.$store.commit('nextPage'); 
        this.$router.push('/pflegehilfsmittel/kontakt');  
      },
    }
};
</script>

