//validate.js
function updateMessage(el, vm) {
    vm.errors = Object.assign({}, vm.errors, {
      [el.name]: el.validationMessage,
    });
}
  
  export const ValidateMixin = {
    data: () => ({
      errors: {},
    }),
    computed: {
      hasErrors() {
        // Check if we have errors.
        return Object.keys(this.errors).some((key) => {
          return !!this.errors[key];
        });
      },
    },
    directives: {
      validate: {
        bind(el, _, vnode) {
          const vm = vnode.context;
          el.addEventListener('input', (e) => {
            updateMessage(e.target, vm);
          });
          vnode.context.$on('validate', () => {
            updateMessage(el, vm);
          });
        },
      },
    },
    methods: {
      validate() {
        this.$emit('validate');
      },
    },
  };