<template>
    <div class="pflbx-configurator">
      <ProgressIndicator />
      <ProductSelection />
      <div class="pflbx-wizard-navigation">
        <button @click="nextPage" type="submit" tag="button" class="pflbx-wizard-button pflbx-wizard-next">weiter</button>
      </div>
    </div>
</template>

<style lang="scss">
</style>

<script>
import ProgressIndicator from "@/components/configurator/ProgressIndicator.vue"
import ProductSelection from "@/components/configurator/ProductSelection.vue"

export default {
    name: 'Configurator',
    components: {
        ProgressIndicator,
        ProductSelection,
    },
    computed: {
      gotoNextPage() {
        return this.$store.state.cart.total < this.$store.state.cart.minimum;
      }
    },
    methods: {
      nextPage() {
        if(this.$store.state.cart.total >=  this.$store.state.cart.minimum) {
          this.$store.commit('nextPage');  
          this.$router.push('/pflegehilfsmittel/patient');    
        }
      }
    }
};
</script>
